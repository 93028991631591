<template>
	<ul class="nav">
		<li class="nav-item" v-for="(tab, index) in tabs" :key="index">
			<a class="nav-link" :class="{ 'active': tab.active }" @click.prevent="tabClick(tab)">
				<span>{{ $t(tab.title) }}</span>
			</a>
		</li>
	</ul>
</template>
<script>
export default {
	props: {
		id: String,
		data: Array
	},
	data: function () {
		return {
			tabs: [...this.data]
		};
	},
	// computed: {},
	// mounted() {
	// 	if (this.$route.hash != "") {
	// 		this.tabs = this.tabs.map((tab) => {
	// 			if (this.$route.hash == '#' + tab.id) {
	// 				let activePanel = document.querySelector(this.getUrl(tab));

	// 				if (activePanel) {
	// 					let old = activePanel.parentElement.querySelector(
	// 						'.tab-pane.active'
	// 					);

	// 					if (old) {
	// 						old.classList.remove('show');
	// 						setTimeout(() => {
	// 							old.classList.remove('active');
	// 						}, 150);
	// 					}

	// 					activePanel.classList.add('show');
	// 					setTimeout(() => {
	// 						activePanel.classList.add('active');
	// 					}, 150);
	// 				}
	// 			}
	// 		});
	// 	}
	// },
	methods: {
		getUrl: function (tab) {
			if (this.id) {
				return '#' + this.id + '-' + tab.id;
			} else {
				return '#' + tab.id;
			}
		},
		tabClick: function (tab) {
			// this.$router.push(this.getUrl(tab));
			if (tab.active) {
				return;
			}

			this.tabs = this.tabs.reduce((acc, item) => {
				if (item.id == tab.id) {
					return [...acc, { ...item, active: true }];
				} else {
					return [...acc, { ...item, active: false }];
				}
			}, []);

			let activePanel = document.querySelector(this.getUrl(tab));

			if (activePanel) {
				let old = activePanel.parentElement.querySelector(
					'.tab-pane.active'
				);

				if (old) {
					old.classList.remove('show');
					setTimeout(() => {
						old.classList.remove('active');
					}, 150);
				}

				activePanel.classList.add('show');
				setTimeout(() => {
					activePanel.classList.add('active');
				}, 150);
			}
		}
	}
};
</script>